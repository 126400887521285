import type MultichannelSdk from '@multichannel/sdk/src/api';
import { EVENT_LIST } from '@multichannel/sdk/src/api/constants';
import Compareable from '@multichannel/sdk/src/Comparable';
import { ScopedLogger } from '@core/logger';

// tslint:disable-next-line:max-classes-per-file
class SdkActionExistsException extends Error { }
// tslint:disable-next-line:max-classes-per-file
class SdkActionNotFoundException extends Error { }
// tslint:disable-next-line:max-classes-per-file
//class SdkActionAlreadyReplacedException extends Error {}

/**
 * This is the actions framework of the multichannel SDK which allows for specific parts of the busines logic to be
 * registered as a callable action. Actions can be fired by any other part of the application, making them a public API.
 * Moreso, actions may even be replaced to drastically change the behaviour of the application. Additionally this class
 * will ensure before and after events being thrown for each fired action.
 *
 * @see http://webdev1.4com.de/kerwitz/pcweb-docs/docs/multichannel-sdk-actions.html
 */
export class Actions extends Compareable {
  /**
   * Get a list of all registered actions as array
   */
  get all() {
    return [...this._map.keys()];
  }
  static get PRIORITIES() {
    return {
      INITIAL: 50,
      DEFAULT: 25,
    };
  }
  private _logger: ScopedLogger;
  private _api: MultichannelSdk = undefined;
  private _map: ActionMap;

  constructor() {
    super();
    this._logger = undefined;
    this._api = undefined;
    this._map = new ActionMap();
  }

  public setApiInstance = (api: MultichannelSdk) => this._api = api;

  public setLogger(logger: ScopedLogger): void {
    this._logger = logger;
  }

  /**
   * Invokes a specific action and triggers before and after events.
   *
   * @param {string} actionName
   * @param {*} payload
   */
  async invoke(actionName: string, ...args) {
    this._logger?.traceCall('invoke', { actionName });

    if (!this._map.has(actionName)) {
      throw new SdkActionNotFoundException(
        `Action "${actionName}" does not exist and cannot be invoked`,
      );
    }

    this._api?.events?.trigger(EVENT_LIST.SDK_ACTIONS_BEFORE, { actionName, args });

    const result = await this._map.invokeActions(actionName, ...args);

    this._api?.events?.trigger(EVENT_LIST.SDK_ACTIONS_AFTER, { actionName, args, result });

    return result;
  }

  /**
   * Registers a new action.
   *
   * @param {string} actionName
   * @param {CallableFunction} action
   */
  public register(actionName: string, action: CallableFunction, priority: number = Actions.PRIORITIES.INITIAL): void {
    this._logger?.traceCall('register', { actionName });

    if (this._map.has(actionName)) {
      throw new SdkActionExistsException(
        `Action "${actionName}" does already exist, use replace if you intend to replace this action`,
      );
    }

    this._map.set(actionName, { execute: action, priority });
  }

  /**
   * Removes a specific action.
   *
   * @param {string} actionName
   */
  public remove(actionName: string): void {
    this._logger?.traceCall('remove', { actionName });

    if (!this._map.has(actionName)) {
      throw new SdkActionNotFoundException(
        `Action "${actionName}" does not exist and cannot be removed`,
      );
    }

    this._map.delete(actionName);
  }

  /**
   * Replaces an action with a given name.
   *
   * @param {string} actionName
   * @param {CallableFunction} action
   */
  public replace(
    actionName: string,
    action: CallableFunction,
    priority: number = Actions.PRIORITIES.DEFAULT): void {
    this._logger?.traceCall('replace', { actionName });

    if (!this._map.has(actionName)) {
      throw new SdkActionNotFoundException(
        `Action "${actionName}" does not exist and cannot be replaced`,
      );
    }
    this._map.set(actionName, { execute: action, priority });
  }
}
/**
 * A proxy class that handles CRUD above Actions overriding Map + priorities.
 */
class ActionMap {

  private _map = new Map();

  public invokeActions = async (name, ...args) => {
    return await this._map.get(name).flush(...args);
  }

  public has = name => {
    return this._map.has(name);
  }

  public get = name => {
    return this._map.get(name);
  }

  public set = (key: any, value: any) => {
    if (this.has(key)) {
      return this._map.get(key).push(value);
    }
    return this._map.set(key, new ActionQueue(value));
  }

  public delete = name => {
    return this._map.delete(name);
  }

  public keys = (): any => {
    return this._map.keys();
  }
}

class ActionQueue {

  private _array: Array<ActionItem>;

  constructor(initial) {
    this._array = [initial];
  }

  public push = value => {
    return this._array.push(value);
  }

  public flush = async (...args) => {
    // sort actions by priorities, lowest is prioritized
    const sorted = this._array.slice().sort((previous, next) => previous.priority - next.priority);

    // returns an array of results. (return only the result of the last?..)
    const resultList = [];

    // Prepare nested call wich allows each action to prevent to execute the following actions
    const next = async () => {
      current = sorted.shift() || ({ execute: () => null, priority: Actions.PRIORITIES.INITIAL });
      const result = await current.execute(...args, next);
      resultList.push(result);
    };

    //call the first action in list
    let current = sorted.shift();
    const result = await current.execute(...args, next);
    resultList.push(result);

    return resultList?.length ?
      resultList.pop() :
      undefined;
  }
}


type ActionItem = {
  execute: Function;
  priority: number;
}

export default new Actions();